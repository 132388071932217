module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"First Rate Merchant","short_name":"First Rate Merchant","start_url":"/","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a4546a10ea40647e7f624a155b9fe162"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K3GF2Z6B","includeInDevelopment":true,"defaultDataLayer":{"type":"function","value":"function() {\n          return {\n            pageType: window.pageType,\n          }\n        }"},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-VSW081JSPV"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"427404493461431"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
